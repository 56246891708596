import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import SignIn from './SignIn';
import Home from './HomeChart';
const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<SignIn />} />
        <Route path='/home' element={<Home/>}/>
      </Routes>

    </BrowserRouter>
  )}
  export default App;