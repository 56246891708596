import React, { useState } from 'react';
import { auth } from './firebase-config'; // Replace './firebase-config' with the correct path to your Firebase config file
import { signInWithEmailAndPassword } from 'firebase/auth';
import { useNavigate } from "react-router-dom";
import './Signin.css'
function SignIn() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    let navigate = useNavigate(); 
    const handleSignIn = async () => {
        try {
            await signInWithEmailAndPassword(auth, email, password);
            let path = `/home`;
            navigate(path);
        } catch (error) {
            console.error('Error signing in: ', error.message);
        }
    };

    return (
        <div className="signin-container">
        <div className="signin-form">
            <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Email"
                className="signin-input"
            />
            <input
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Password"
                className="signin-input"
            />
            <button onClick={handleSignIn} className="signin-button">Sign In</button>
        </div>
    </div>
    );
}
export default SignIn;