import React, { useState, useEffect } from 'react';
import { db, auth } from './firebase-config';
import { collection, getDocs, query,where } from 'firebase/firestore';
import { onAuthStateChanged } from 'firebase/auth';
import { Line } from 'react-chartjs-2';
import 'chartjs-adapter-date-fns';
import 'chart.js/auto';
import './HomeChart.css'
import logoMain from './LogoMain.png'
function Home() {
    const [documents, setDocuments] = useState([]);
    const [documents2, setDocuments2] = useState([]);
    const [documents3, setDocuments3] = useState([]);
    const [user, setUser] = useState(null);
    const [timeRange, setTimeRange] = useState('7d');
    const [chartData, setChartData] = useState([])
    const [chartData2, setChartData2] = useState([])
    const [chartData3, setChartData3] = useState([])
    const [displayMode, setDisplayMode] = useState('btc_worth'); // 'btc_worth' or 'percent_change'

    const collections = [
        { ref: collection(db, 'RFC V2 FCAT'), setDoc: setDocuments },
        { ref: collection(db, 'RFC V2 LCAT'), setDoc: setDocuments2 },
        { ref: collection(db, 'RFC V2 LLCAT'), setDoc: setDocuments3 }
    ];

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, currentUser => {
            setUser(currentUser);
        });
        return () => unsubscribe();
    }, []);

    useEffect(() => {
        const fetchDocuments = async () => {
            if (user) {
                collections.forEach(async ({ ref, setDoc }) => {
                    try {
                        const q = query(ref);
                        const data = await getDocs(q);
                        const minimumTime = new Date('2024-02-23T15:30:42Z').getTime();
                        const docs = data.docs.map(doc => {
                            const docData = doc.data();
                            const tradeTime = docData.Time ? new Date(docData.Time.seconds * 1000) : new Date(0);
                            const btc_bal = Number(docData.BTC_bal) + Number(docData.Order_Amount);
                            const usd_bal = Number(docData.USDT_bal);
                            const price = Number(docData.Price);
                            const btc_worth = btc_bal * price + usd_bal;

                            return { ...docData, id: doc.id, btc_worth, tradeTime };
                        })
                        .filter(doc => doc.tradeTime.getTime() >= minimumTime)
                        .sort((a, b) => b.tradeTime - a.tradeTime);

                        setDoc(docs);
                    } catch (error) {
                        console.error("Error fetching documents:", error);
                    }
                });
            }
        };

        fetchDocuments();
    }, [user]);

    useEffect(() => {
        // Ensure the function is called with the correct documents array as argument
        if (documents.length > 0) {
            setChartData(filterAndAggregateData(documents));
        }
        if (documents2.length > 0) {
            setChartData2(filterAndAggregateData(documents2));
        }
        if (documents3.length > 0) {
            setChartData3(filterAndAggregateData(documents3));
        }
    }, [documents, documents2, documents3, timeRange]);


    const toggleDisplayMode = () => {
        setDisplayMode(displayMode === 'btc_worth' ? 'percent_change' : 'btc_worth');
    };

    const calculatePercentChange = (data) => {
        if (!data || data.length === 0 || !data[0].btc_worth) return [];
        const firstValue = data[0].btc_worth;
        return data.map(item => ({
            ...item,
            btc_worth: firstValue !== 0 ? ((item.btc_worth - firstValue) / firstValue) * 100 : 0
        }));
    };
    const logic = (jsonObj) => {
        if (!jsonObj || jsonObj.Time.seconds === undefined) {
            return { btc_worth: 0, tradeTime: 0 };
        }
        let tradeTime = new Date(jsonObj.Time.seconds * 1000 + (jsonObj.Time.nanoseconds || 0) / 1000000);
        let USDT_bal = jsonObj['USDT_bal'] || 0;
        let BTC_bal = jsonObj['BTC_bal'] || 0;
        let price = Number(jsonObj['Price'] || 0);
        let btc_worth = (BTC_bal * price) + USDT_bal;
        return { btc_worth, tradeTime: tradeTime.getTime() };
    };
    const filterAndAggregateData = (docs) => {
        const fixedBTCAmount = 0.01962862639;
        const now = new Date();
        const oneDay = 24 * 60 * 60 * 1000;
        const sevenDays = 7 * oneDay;
        const thirtyDays = 30 * oneDay;
    
        let timeDifference;
        switch (timeRange) {
            case '1d':
                timeDifference = oneDay;
                break;
            case '7d':
                timeDifference = sevenDays;
                break;
            case '1m':
                timeDifference = thirtyDays;
                break;
            default:
                timeDifference = oneDay;
        }
        const filteredDocs = docs.filter(doc => (now - doc.tradeTime) <= timeDifference);
        const sampledData = filteredDocs.filter((_, index) => {
            const totalPoints = filteredDocs.length;
            const samplingRate = Math.ceil(totalPoints / 100);
            return index % samplingRate === 0;
        });
        const sampleMap = sampledData.map(doc => {
            const nonTradingValue = fixedBTCAmount * Number(doc.Price);
            return { ...logic(doc), nonTradingValue };
        });
        return sampleMap.reverse();
    };
    

    const handleTimeRangeChange = (range) => {
        setTimeRange(range);
    };
    const getChartData = (data) => {
        return displayMode === 'percent_change' ? calculatePercentChange(data) : data;
    };

    const chartDataProcessed = getChartData(chartData);
    const chartData2Processed = getChartData(chartData2);
    const chartData3Processed = getChartData(chartData3);
    
    const chartjsData = {
        labels: chartDataProcessed.map(item => new Date(item.tradeTime)), // Ensure this is compatible with your data structure
        datasets: [
            {
                label: displayMode === 'btc_worth' ? 'Bot 1: Standard Algorithm, no trailing stop' : 'Bot 1: Standard Algorithm no trailing stop (% Change)',
                data: chartDataProcessed.map(item => item.btc_worth),
                borderColor: 'Red',
                backgroundColor: 'rgb(255,0,0,0.5)',
            },
            {
                label: displayMode === 'btc_worth' ? 'Bot 2: Trailing Stop 0.03%, 1 week': 'Bot 2: Trailing Stop 0.03%, 1 week (% Change)',
                data: chartData2Processed.map(item => item.btc_worth),
                borderColor: 'Orange',
                backgroundColor: 'rgba(255, 165, 0, 0.5)',
            },
            {
                label: displayMode === 'btc_worth' ? 'Bot 3: Trailing Stop (0.03%), 30 minutes': 'Bot 3: Trailing Stop (0.03%), 1 hour (% Change)',
                data: chartData3Processed.map(item => item.btc_worth),
                borderColor: 'Green',
                backgroundColor: 'rgba(34, 139, 34, 0.5)',
            },
        ]
    };
    function firestoreTimestampToDate(timestamp) {
        return new Date(timestamp.seconds * 1000);
     }

    if (!user) {
        return <div>Please log in to view the documents</div>;
    }

    const trades = (doc) => {
        var showTrade = false;
        const price = doc['Price'];
        const qty = doc['Order_Amount']; 
        const order = doc['Order']
        const time = firestoreTimestampToDate(doc['Time']);
        if (order != null){
            if(order != "No order"){
                showTrade = true

            }
        }
        return { price, qty, time, showTrade, order };
    };
    
    const renderTrades = (docs) => {
        return docs.map((doc) => {
            const { price, time, showTrade, order } = trades(doc);   
            if (showTrade == false) {
                return
            }
            console.log(order["side"])
            const formattedTradeTime = time.toLocaleString();
    
            const tradeClass = order["side"] === 'BUY' ? 'trade buy' : 'trade sell';
            return (
                <div key={doc.id} className={tradeClass}>
                    <span className="trade-detail"> {order["side"]} </span>
                    <span className="trade-detail">Price: {price} </span>
                    <span className="trade-detail">Amount: {order["executedQty"]}</span>
                    <span className="trade-detail"> {formattedTradeTime}</span>

                </div>
                
            );
        });
    };

    const getTimeAxisSettings = (timeRange) => {
        switch (timeRange) {
            case '1d':
                return { unit: 'hour', stepSize: 2, tooltipFormat: 'MMM d, h:mm a' };
            case '7d':
                return { unit: 'day', stepSize: 0.5, tooltipFormat: 'MMM d, h:mm a' };
            case '1m':
                return { unit: 'day', stepSize: 2, tooltipFormat: 'MMM d' };
            default:
                return { unit: 'day', stepSize: 1, tooltipFormat: 'MMM d' };
        }
    };

    const { unit, stepSize, tooltipFormat } = getTimeAxisSettings(timeRange);

    const options = {
        scales: {
            y: {
                type: 'linear',
                display: true,
                position: 'left',
                ticks: {
                    callback: function(value, index, values) {
                        return displayMode === 'percent_change' ? `${value}%` : `$${value}`;
                    }
                }
            },
            x: {
                type: 'time',
                time: {
                    unit: unit,
                    stepSize: stepSize,
                    tooltipFormat: tooltipFormat,
                    displayFormats: {
                        hour: 'h:mm a',
                        day: 'MMM d'
                    }
                },
                ticks: {
                    autoSkip: true,
                    maxTicksLimit: 20
                }
            }
        },
        responsive: true,
        maintainAspectRatio: false
    };

    return (
        <div className='page'>
            <img src={logoMain} alt="Logo" className="logo" />
        <div className='title-parent'>
            <div className='title'>
                Golden Egg Tracker
            </div>
        </div>
        <div className='chart-container'>
            <Line data={chartjsData} options={options} />
        </div>
        <div className='button-container'>
            {displayMode === 'btc_worth' && (
                <>
                    <button onClick={() => handleTimeRangeChange('1d')}>1 Day</button>
                    <button onClick={() => handleTimeRangeChange('7d')}>7 Days</button>
                    <button onClick={() => handleTimeRangeChange('1m')}>1 Month</button>
                </>
            )}
            <div className="percent-toggle">
                <span className='switch-span'>% Change</span>
                <label className="switch">
                    <input type="checkbox" checked={displayMode === 'percent_change'} onChange={toggleDisplayMode} />
                    <span className="slider round"></span>
                </label>
            </div>
        </div>
             <div className='trade-tables-container'>
                <div className="trade-table">
                    <h3>Bot 1</h3>
                    <h4>Standard Algorithm, no trailing stop, adaptive threshold looking at all data.</h4>

                    {renderTrades(documents)}
 
                </div>
                <div className="trade-table">
                    <h3>Bot 2</h3>
                    <h4>Trailing stop of 0.03%, adaptive threshold looking at the past week of data.</h4>

                    {renderTrades(documents2)}
                </div>
                <div className="trade-table">
                    <h3>Bot 3</h3>
                    <h4>Trailing stop of 0.03%, adaptive threshold looking at the past half hour of data.</h4>
                    {renderTrades(documents3)}
                </div>
        </div>
        
    </div>
    );
}
export default Home;